<template>
  <div class="Login-Page" v-if="cursus">
    <div class="header-large">
      <div class="top"></div>
      <div class="center">
        <div class="container main-container">
          <div class="content-wrapper">
            <div class="top-wrapper">
              <div class="logo-wit" v-bind:class="{ 'mt-6': isNativeApp }"></div>
              <div class="title">Join Alpha Youth</div>
              <div class="subtitle">In {{ cursus.Title }}</div>
              <div class="inleiding">
                Gaaf dat je onderdeel wordt van {{ cursus.Title }}. Vul hieronder je gegevens in dan laten we je binnen.
              </div>
              <message :error="error" :success="success" />
              <form class="form" @submit.prevent v-if="success == null">
                <div class="row">
                  <div class="col-6">
                    <div class="field">
                      <label for="voornaam">Voornaam</label>
                      <input type="text" placeholder="Voornaam" id="voornaam" v-model="info.firstname" v-bind:class="{ 'error' : errorFields.indexOf('firstname') !== -1 }" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="field">
                      <label for="achternaam">Achternaam</label>
                      <input type="text" placeholder="Achternaam" id="achternaam" v-model="info.lastname" v-bind:class="{ 'error' : errorFields.indexOf('lastname') !== -1 }" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="field">
                      <label for="email">E-mailadres</label>
                      <input type="text" placeholder="E-mailadres" id="email" v-model="info.email"  v-bind:class="{ 'error' : errorFields.indexOf('email') !== -1 }" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="field buttons">
                      <button @click="onSubmit" class="button grass-green">Aanmelden</button>
                      <router-link to="/login" class="forgot-password">Ik heb al een account</router-link>
                    </div>
                  </div>
                </div>
              </form>
              <span v-else class="back">
                <router-link to="/login" class="forgot-password">Ga naar het inlogscherm</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>
    <div class="container">
      <div class="content-wrapper">
        <div class="vrienden"></div>
      </div>
    </div>
    <modal-loading v-if="loading" :title="`Registeren...`"/>
  </div>
</template>
<script>
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react";
import router from "@/router";
import Message from "@/views/components/message-element";
import ModalLoading from "@/views/components/modal-loading";

export default {
  name: "registreer-page",
  components: {ModalLoading, Message},
  created() {
      if (this.$route.params.token) {
        this.token = this.$route.params.token;
        this.getCursus();
      } else {
        this.$root.doneLoading();
      }
    },
    data () {
        return {
            cursus: null,
            info: {},
            loading: false,
            token: null,
            error: null,
            success: null,
            isNativeApp: react.isNativeApp(),
            errorFields: [],
        }
    },
    methods: {
        getCursus() {
          if(this.token) {
            httpClient.get('/course/' + this.$route.params.token)
                .then(response => {
                  this.cursus = response.data
                })
                .finally(() => {
                  this.$root.doneLoading();
                });
          }
        },
        onSubmit: function (e) {
          this.loading = true;
          httpClient.post('/user/register/' + this.$route.params.token, JSON.stringify(this.info))
              .then(response => {
                this.error = null
                this.success = "Gelukt, je hoort erbij! Check je e-mail voor de volgende stap. Controleer ook je spam als je hem niet kunt vinden."
                this.info = {};
                this.loading = false;
              }).catch(error => {
                this.error = error.response.data.Message
                this.errorFields = error.response.data.fields
                this.loading = false;
          })
        }
    }
}
</script>

<style scoped>

</style>
