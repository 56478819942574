<script setup>
import Editor from '@tinymce/tinymce-vue'
</script>

<template>
  <div class="Avond-Page noselect" v-if="avond">
    <div class="header">
      <div class="inner-header">
        <div class="container">
          <div class="top">
            <span class="back-button" v-bind:class="{ 'mt-6': isNativeApp }" @click="$root.backClick({ name: 'Avonden' }, true)"></span>
            <profile-icon />
          </div>
          <div class="bottom">
            <div class="row">
              <div class="col-lg-12 col-2 bijeenkomst-wrapper">
                <span class="bijeenkomst">Bijeenkomst </span>
                <span class="bijeenkomst-nr">#{{avond.Index }}</span>
              </div>
              <div class="col-lg-8">
                <div class="title" v-bind:class="{ 'mt-4': isNativeApp }">
                  {{ avond.Title }}
                </div>
              </div>
              <div class="col-lg-4 col-10">
                <date-element @click="editDateClick()" :avond="avond" :editable="true" />
              </div>
            </div>
          </div>
          <div class="print" onclick="window.print()"></div>
          <div class="menu">
            <div class="button dark-blue" @click="programmaSelected = true" v-bind:class="{ active: programmaSelected }">
              Programma
            </div>
            <div class="button dark-blue" @click="programmaSelected = false" v-bind:class="{ active: !programmaSelected }">
              Doel
              &amp; voorbereiding
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container main-container">
      <div v-show="programmaSelected" class="programma-wrapper">
        <div class="line"></div>
        <draggable
            :list="avond.Items"
            item-key="name"
            class="programma"
            handle=".handle"
            direction="vertical"
            :delay="200"
            :delay-on-touch-only="true"
            @end="onEnd">
          <template #item="{ element }">
            <item-element :element="element" />
          </template>
        </draggable>
      </div>
      <div v-show="!programmaSelected">
        <div class="doel-info global-text">
          <span v-if="$root.isAdmin()">
            <message :error="error" :success="success" />
            <Editor
                api-key="eibe54i1t655h2gvpo6w5mx2kv98rnbxm8brttz58m5zkzgt"
                :inline=true
                v-model="avond.Doel"
                :init="{
                menubar: false,
                plugins: 'lists link image'
              }" />
            <div class="btn btn-primary text-white mt-3 mb-3" @click="saveContent">Opslaan</div>
          </span>
          <span v-else v-html="avond.Doel"></span>
        </div>
      </div>
    </div>
    <dialog-datum ref="dialogDate" :id="avond.ID" :date="avond.Datum" v-on:ok="changedDate"/>
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react";
import draggable from 'vuedraggable'
import { nl } from 'date-fns/locale'
import moment from 'moment'
import ItemElement from "@/views/components/item-element";
import ProfileIcon from "@/views/components/profile-icon";
import Message from "@/views/components/message-element";
import DateElement from "@/views/components/date-element";
import DialogDatum from "@/views/components/dialog-datum";

export default {
  name: "avond-page",
  components: {
    DialogDatum,
    DateElement,
    ProfileIcon,
    ItemElement,
    draggable,
    Message
  },
  created() {
    httpClient.get('/avond?id=' + this.$route.params.id)
        .then(response => {
          var data = response.data;
          try {
            data.Datum = moment.utc(data.Datum).toDate()
          } catch (e) {
            data.Datum = moment.now()
          }
          console.log(data.Datum)
          data.Time = moment(data.Datum).format("HH:mm")
          this.avond = data
          this.calculateTime()
        })
        .finally(() => {
          this.$root.doneLoading();
        });
  },
  data() {
    return {
      avond: null,
      programmaSelected: true,
      date: null,
      files: null,
      locale: nl,
      isNativeApp: react.isNativeApp(),
      error: null,
      success: null
    }
  },
  methods: {
    changedDate(value) {
      this.avond.Datum = value.date
      this.calculateTime()
    },
    editDateClick: function () {
      if(this.$root.isAdmin()) {
        this.$root.openDialog(this.$refs.dialogDate.$refs, 'dialogDate')
      }
    },
    onEnd: function (value) {
      var indices = this.avond.Items.map(item => item.ID);
      httpClient.post('/sort', JSON.stringify(
          {AvondID: this.avond.ID, Indices: indices}
      ))
      this.calculateTime()
    },
    saveContent:function () {
      httpClient.post('/content/avond', JSON.stringify(
          {id: this.avond.ID, content: this.avond.Doel}
      )).then(response => {
        this.success = "Succesvol opgeslagen!"

        setTimeout(function () {
          this.success = null
        }.bind(this), 3000)
      })
    },
    calculateTime: function () {
      this.date = this.avond.Datum;
      this.avond.Items.forEach((value) => {
        value.Date = moment.utc(this.date).format('HH.mm')
        this.date = new Date(this.date.getTime() + value.Time * 60000);
      });
    },

  }

}
</script>

