<template>
  <div class="Login-Page">
    <div class="header-large">
      <div class="top"></div>
      <div class="center">
        <div class="container main-container">
          <div class="content-wrapper">
            <div class="top-wrapper">
              <div class="logo-wit" />
              <div class="title">Alpha Youth</div>
              <div class="subtitle">Herstel je wachtwoord</div>
              <div class="inleiding"></div>
              <message :error="error" />
              <form class="form login-form" @submit.prevent>
                <label for="wachtwoord">Wachtwoord</label>
                <input type="password" id="wachtwoord" v-bind:value="this.password.First" v-on:input="password.First = $event.target.value"/>
                <label for="repeat">Herhaal wachtwoord</label>
                <input type="password" id="repeat" v-bind:value="this.password.Second" v-on:input="password.Second = $event.target.value"/>
                <div class="buttons">
                  <button @click="changePassword()" class="button grass-green button-save">Wijzig</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container no-padding">
      <div class="content-wrapper">
        <div class="vrienden"></div>
      </div>
    </div>
    <modal-loading v-if="loading" :title="`Gegevens checken...`"/>
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import Message from "@/views/components/message-element";
import router from "@/router";

export default {
  components: {Message},
  created() {
    this.$root.doneLoading();
  },
  methods: {
    changePassword() {
      var validate = this.validatePassword();
      if(validate === true) {

        httpClient.post('/user/resetPassword', JSON.stringify(this.password))
            .then(response => {
              this.password = {};
              router.push('/login?reset=true')
            })
            .catch(error => {
              this.error = error.response.data.Message
            })
      }
    },
    validatePassword() {

      if(this.password.First === null || this.password.Second === null) {
        this.error = "Vul beide velden in";
        return false;
      }

      if(this.password.First !== this.password.Second) {
        this.error = "Wachtwoorden komen niet overeen";
        return false;
      }

      if(this.password.First.length < 8) {
        this.error = "Wachtwoord moet uit minimaal 8 karakters bestaan";
        return false;
      }

      this.error = null
      return true;
    }
  },
  data() {
    return {
      error: null,
      password: {
        First: null,
        Second: null,
        ID: this.$route.params.id,
        token: this.$route.params.token
      }
    }
  }
}
</script>
