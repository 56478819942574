import './assets/css/main.css';
import './assets/css/print.css';
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { createStore } from 'vuex'
import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import notifications from '@kyvg/vue3-notification'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(notifications)
app.mount('#app')



// should refactor some day
app.directive('tooltip', function(el, binding){
    var showTooltip = function () {
        var txt = binding.value.title
        const newDiv = document.createElement("div");
        newDiv.classList.add("arrow_box");

        const canvas = document.createElement("span");
        canvas.innerText = txt
        document.body.appendChild(canvas);
        var txtFrame = canvas.getBoundingClientRect();
        var width = txtFrame.width + 10;
        var height = txtFrame.height + 10;
        newDiv.appendChild(canvas);

        var rect = el.getBoundingClientRect();
        //newDiv.style.left = rect.x + "px";
        newDiv.style.top = rect.height + "px";
        newDiv.style.width = width + "px";
        newDiv.style.height = height + "px";
        newDiv.style.borderRadius = (height / 2) + "px";



        el.parentElement.appendChild(newDiv);
        setTimeout(function () {
            newDiv.classList.add("visible");
        })

        el.addEventListener('mouseleave', function () {
            if (binding.value.dismiss) {
                newDiv.classList.remove("visible");
                newDiv.remove()
            } else {
                setTimeout(function () {
                    newDiv.classList.remove("visible");
                    setTimeout(function () {
                        newDiv.remove()
                    }, 1000)
                }, 500);
            }
        });
    }

    el.addEventListener(binding.value.trigger, function () {
       showTooltip();
    });

    el.addEventListener(binding.value.trigger, showTooltip)

    if(binding.value.visible) {
        showTooltip();
    }

})