<template>
  <div class="header">
    <div class="inner-header">
      <div class="container">
        <div class="top">
          <span class="back-button" v-bind:class="{ 'mt-6': isNativeApp, invisible: !back }" @click="$root.backClick(location, force)"></span>
          <profile-icon />
        </div>
        <div class="bottom">
          <div class="row">
            <div class="col-lg-8">
              <div v-bind:class="{ 'mt-4': isNativeApp }" class="title" v-text="title"></div>
            </div>
            <div class="col-lg-4">
            </div>
          </div>
        </div>
        <div class="menu" v-if="!hideMenu">
          <router-link to="/avonden" class="button dark-blue">Bijeenkomsten</router-link>
          <router-link to="/team" class="button dark-blue">Team</router-link>
          <template v-for="(page, index) in $root.pages" v-bind:key="index">
            <router-link :to="{name:'Page', params: {'id':index}}" class="button dark-blue" v-text="page"></router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileIcon from "@/views/components/profile-icon";
import react from "@/functions/react";
export default {
  components: {ProfileIcon},
  props: {
    'title': String,
    'location': Object,
    'back': Boolean,
    'force': {
      type: Boolean,
      default: false
    },
    'hideMenu': {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
        isNativeApp: react.isNativeApp()
    }
  },
}
</script>
