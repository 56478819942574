<template>
  <div class="date-column" v-if="dates">
     <span class="icon-cal" v-for="(date, id) in dates" v-bind:key="id">
        {{ getDay(date) }}
      </span>
    <span class="date" v-html="format_date()"></span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    dates: {},
    editable: Boolean
  },
  methods: {
    getDay(date) {
      date = moment.utc(date)
      return date.format('D')
    },
    stringToDate(date) {
      return moment.utc(date)
    },
    format_date() {
      if(this.dates.length >= 2) {
        let first = moment.utc(this.dates[0])
        let last =  moment.utc(this.dates[this.dates.length-1])
        let firstFormatted = first.format('dddd D MMM')
        let lastFormatted = last.format('dddd D MMM')
        return firstFormatted + " - <br />" + lastFormatted
      }

      // if (this.avond.Datum != null) {
      //   var date = this.stringToDate();
      //   return date.format('dddd D MMM YYYY <br />HH.mm uur')
      // }
      return ""
    }
  }
}
</script>

<style scoped>

</style>