<template>
  <div class="Login-Page Aanmeldcode-Page">
    <div class="header-large">
      <div class="top"></div>
      <div class="center">
        <div class="container main-container">
          <div class="content-wrapper">
            <div class="top-wrapper">
              <div class="logo-wit" v-bind:class="{ 'mt-6': isNativeApp }"></div>
              <div class="title">Alpha Youth</div>
              <div class="subtitle">Aanmelden met aanmeldcode</div>
              <div class="inleiding">
                Je hebt waarschijnlijk van je teamleider een unieke code ontvangen, super! Met deze code meld je je direct aan voor jouw Alpha Youth team:
              </div>
              <message :error="error"/>
              <form class="form" @keyup.enter="onSubmit" @submit.prevent>
                <input type="text" v-model="code" placeholder="######" />
                <div class="buttons">
                  <button @click="onSubmit" class="button grass-green button-save">Volgende</button>
                  <router-link to="/login" class="forgot-password">Terug naar Inloggen</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
    </div>
    <div class="container no-padding">
      <div class="content-wrapper">
        <div class="vrienden"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/views/components/message-element";
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react";
import router from "@/router";

export default {
  name: "aanmeld-code",
  components: {Message},
  data() {
    return {
      code: null,
      error: null,
      isNativeApp: react.isNativeApp(),
    }
  },
  created() {
    this.$root.doneLoading();
  },
  methods: {
    onSubmit() {
      httpClient.get('/course/' + this.code)
        .then(response => {
          console.log(response)
          this.error = null;
          router.push({ name: 'registreer', params: { token: this.code } })
        }).catch(e => {
          this.error = e.response.data.Message
      })
    }
  }
}
</script>
