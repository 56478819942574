<template>
  <div class="Avonden-Page header-with-tabs noselect" v-if="data">
    <global-header :title="data.title" :back="false" :location="{ name: 'Home' }" />
    <div class="container main-container no-padding">
      <div class="avonden">
          <template v-for="avond in data.items" v-bind:key="avond.ID">
            <div class="avond training" @click="itemClick(avond.ID, avond.Hidden)" :class="hiddenAndLast(avond.ID, avond.Hidden)">
              <div class="inner">
                <div class="title">{{ avond.Title }}</div>
                <date-element :avond="avond" :editable="false" />
              </div>
              <div class="collapse"></div>
            </div>
          </template>
        <div class="seperator" v-show="!firstItemHidden"></div>

        <div class="none" />
          <template v-for="avond in data.evenings" v-bind:key="avond.ID" >
            <div v-if="avond.isWeekend" class="avond weekend">
              <div class="inner">
                <div class="title">{{ avond.Title }} </div>
                <weekend-element :dates="avond.dates" :editable="false" />
              </div>
            </div>
            <div v-else class="avond" @click="avondClick(avond.ID, avond.Hidden)" :class="hiddenAndLast(avond.ID, avond.Hidden, avond.Weekend)">
              <div class="inner">
                <div class="title">{{ avond.Title }}</div>
                <date-element :avond="avond" :editable="false" />
              </div>
              <div class="collapse"></div>
            </div>
          </template>
      </div>
    </div>
  </div>
</template>
<script>
import { fetcher } from "@/api/httpClient";
import router from "@/router";
import DateElement from "@/views/components/date-element";
import ProfileIcon from "@/views/components/profile-icon";
import GlobalHeader from "@/views/components/global-header";
import useSWRV from 'swrv'
import WeekendElement from "@/views/components/weekend-element";

export default {
  components: {
    WeekendElement,
    GlobalHeader,
    DateElement,
  },
  methods: {
    avondClick(id, hidden) {
      if(hidden) {
        this.collapse()
        return
      }

      router.push({ name: 'avond', params: { id: id} })
    },
    itemClick(id, hidden) {
      if(hidden) {
        this.collapse()
        return
      }

      router.push({name: 'item', params: {itemId: id}})
    },
    collapse() {
      this.data.items.forEach(e => e.Hidden = false)
      this.data.evenings.forEach(e => e.Hidden = false)
    },
    hiddenAndLast(id, hidden, weekend) {
      let isWeekend = weekend !== 0 ? "weekend-item" : ""
      if(!hidden)
        return isWeekend

      var last = false
      this.data.items.forEach(function (value, index) {
        if(value.Hidden) {
          if (value.ID === id) {
            last = true
          } else {
            last = false
          }
        }
      });
      this.data.evenings.forEach(function (value, index) {
        if(value.Hidden) {

          if (value.ID === id) {
            last = true
          } else {
            last = false
          }
        }
      });



      if(last) {
        //return "hidden last " + isWeekend
        return "last " + isWeekend
      } else {
        //return "hidden" + isWeekend
        return isWeekend
      }

    },
  },
  computed: {
    firstItemHidden() {
      //return this.data.evenings.filter(item => item.Index === 1 && item.Hidden).length != 0
      return false
    }
  },
  setup() {
    const {data, error} = useSWRV('/course/evenings', fetcher);
    return {
      data,
      error,
    }
  },
  created() {
    this.data !== undefined && this.$root.doneLoading();
  },
  watch: {
    data(value) {
      if (value !== undefined) {
        this.$root.doneLoading();

      }
    }
  },
}
</script>
<style scoped>
.list-group-item {
    background: lightblue;
    height: 50px;
    border: 1px solid black;
}
.buttons {
    margin-top: 35px;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.not-draggable {
    cursor: no-drop;
}
</style>
