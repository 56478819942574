import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
    // state
    state: {
        isLoading: '',
        path: null
    },

    // getters
    getters: {
        isLoading: state => state.isLoading,
    },

    // actions
    actions: {
        // change data
        setLoading({commit}, type){
            commit('setLoading', type);
        },
        setPath({commit}, path) {
            commit('setPath', path);
        }
    },

    // mutations
    mutations: {
        setLoading ( state, type ){
            state.isLoading = type;
        },
        setPath ( state, path ){
            state.path = path;
        },
    }
})
export default store;