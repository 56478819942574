<template>
  <div class="Promo-Page header-with-tabs" v-if="content">
    <global-header :title="title" :back="true" :location="{ name: 'Home' }" />
    <div class="container main-container">
      <div v-for="category in content" v-bind:key="category.ID">
        <div class="title"><strong>{{ category.Title }}</strong></div>
        <div class="txt">{{ category.Text }}</div>
        <ul class="downloads">
          <li v-for="download in category.Downloads" v-bind:key="download.ID">
            <a :href="download.ExterneLink" target="_blank" v-if="download.ExterneLink">
              <div class="download-title">{{ download.Title }}</div>
              <div class="download-txt">{{ download.Description }}</div>
            </a>
            <a :href="download.Bestand" target="_blank" v-else>
              <div class="download-title">{{ download.Title }}</div>
              <div class="download-txt">{{ download.Description }}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalHeader from "@/views/components/global-header";
import {httpClient} from "@/api/httpClient";
import {accordion} from "@/functions/accordion";

export default {
  components: {GlobalHeader},
  data() {
    return {
      content: null,
      title: "Promotiemateriaal"
    }
  },
  created() {
    httpClient.get('/downloads')
        .then(response => {
          this.content = response.data
          accordion();
        })
        .finally(() => {
          this.$root.doneLoading();
        });
  }
}
</script>