var addAppToWebListener = function ()
{
    const listener = ((e) => {
        const {type, payload} = e.detail;

        if(type === 'push'){
            // alert('register push => ' + payload)
        }
    });
    window.addEventListener("nativeApp", listener);
}

var postMessageToApp = function (type, payload = '') {
    if (!window.ReactNativeWebView || typeof window === "undefined") {
        console.log("React not available type => " + type + " payload => " + payload)
        return;
    }

    window.ReactNativeWebView.postMessage(JSON.stringify({
        type,
        payload,
    }));
}

var isNativeApp = function ()
{
    return !!(typeof window !== "undefined" && window.ReactNativeWebView);
}

export default
{
    addAppToWebListener,
    postMessageToApp,
    isNativeApp,
}
