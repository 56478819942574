<template>
  <div class="Profile-Page header-without-tabs" v-if="this.$root.user">
    <div class="header">
      <div class="inner-header">
        <div class="container">
          <div class="top">
            <span class="back-button" v-bind:class="{ 'mt-6': isNativeApp }" @click="$root.backClick({ name: 'Home' })"></span>
          </div>
          <div class="bottom">
            <div class="row">
              <div class="col-lg-8">
                <div class="title">Mijn wachtwoord wijzigen</div>
              </div>
              <div class="col-lg-4">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-wrapper">
      <div class="container main-container">
        <p>Wijzig je wachtwoord hieronder</p>
        <div class="image"></div>
        <message :error="error" :success="success" />

        <div class="form">
          <div class="row top">
            <div class="col-12">
              <div class="field">
                <label for="wachtwoord">Wachtwoord</label>
                <input type="password" id="wachtwoord" v-bind:value="this.password.First" v-on:input="password.First = $event.target.value"/>
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label for="repeat">Herhaal wachtwoord</label>
                <input type="password" id="repeat" v-bind:value="this.password.Second" v-on:input="password.Second = $event.target.value"/>
              </div>
            </div>
            <div class="col-12">
              <a @click="changePassword()" class="button grass-green button-save">Opslaan</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react"
import Message from "./components/message-element";

export default {
  components: {Message},
  created() {
    this.$root.doneLoading();
  },
  watch: {
    password: {
      handler(val){
        if(Object.keys(val).length > 0) {
          this.success = null
          this.validatePassword();
        }
      },
      deep: true
    }
  },
  methods: {
    changePassword() {
      var validate = this.validatePassword();
      if(validate === true) {
        httpClient.post('/user/changePassword', JSON.stringify(this.password)).then(response => {
          this.password = {};
          this.error = null
          this.success = "Wachtwoord gewijzigd"
        }).catch(error => {
          this.error = error.response.data.Message
          this.success = null
        })

      }
    },
    validatePassword() {
      if(this.password.First == undefined || this.password.Second == undefined) {
        this.error = null
        return false;
      }

      if(this.password.First != this.password.Second) {
        this.error = "Wachtwoorden komen niet overeen";
        return false;
      }

      if(this.password.First.length < 8) {
        this.error = "Wachtwoord moet uit minimaal 8 karakters bestaan";
        return false;
      }

      this.error = null
      return true;
    }
  },
  data() {
    return {
      password: {},
      error: null,
      success: null,
      isNativeApp: react.isNativeApp(),
    }
  }
}
</script>
