<template>
  <router-view :key="$route.fullPath || 'default'" v-slot="{ Component }">
    <loading v-if="isLoading"></loading>
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <div class="app-notification" v-if="isMobile() && !mobileHidden">
    <div class="container">
      <div class="col-80">
        <a class="link" :href="isMobile()" target="_blank">We hebben ook een Alpha Youth app voor mobiel</a>
        <div class="close" v-bind:class="{ 'mt-5': isNativeApp }" @click="hideMobile()"></div>
      </div>
    </div>
  </div>
  <notifications position="bottom center" classes="vue-notification"/>
</template>
<script>


import { httpClient } from "@/api/httpClient";
import moment from "moment";
import router from "@/router";
import { mapState } from 'vuex'
import store from "@/store";
import Loading from "@/views/components/loading-element";
import react from "@/functions/react"

export default {
  components: {Loading},
  data() {
        return {
            pages: [],
            name: "",
            image: null,
            user: {},
            mobileHidden: react.isNativeApp(),
            isNativeApp: react.isNativeApp(),
        }
  },
  watch: {
    user(value) {
      if (value != null) {
        react.postMessageToApp("userId", value.ID)
      }
    }
  },
  mounted() {
    react.addAppToWebListener();
  },
  computed:{
    ...mapState({
      isLoading: 'isLoading'
    }),
  },
    created() {
        moment.locale("nl")
        httpClient.get("/user")
            .then(response => {
                this.user = response.data;
                this.name = response.data.Name
                this.image = response.data.Image
              console.log(this.user);
            },
            response => {
              console.log(response.status)
            })

      httpClient.get("/content/pages")
          .then(response => {
            this.pages = response.data;
          })
    },
    methods: {
      doneLoading() {
        store.commit('setLoading', false)
      },
      openDialog(refs, name) {
        refs[name].classList.add('visible')
        document.body.classList.add('hide-overflow')
      },
      closeDialog(refs, name) {
        refs[name].classList.remove('visible')
        document.body.classList.remove('hide-overflow')
      },
      isAdmin() {
        return this.user.Role === 0
      },
      hideMobile() {
        localStorage.setItem("hideMobile", "1");
        this.mobileHidden = true;
      },
      isMobile()  {
        if(localStorage.getItem("hideMobile") !== null) {
          this.mobileHidden = true;
        }
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
          return "android url";
        }

        if (/iPhone/.test(userAgent) && !window.MSStream) {
          return "ios url";
        }
      },
      backClick(location, force = false) {
        if(window.history.length >= 1 && !force) {
          router.go(-1)
        } else {
          router.push(location)
        }
      }
    }
}
</script>
