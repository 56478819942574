<template>
  <div class="Profile-Page header-with-tabs" v-if="this.$root.user && this.loaded">
    <global-header title="Mijn gegevens wijzigen" :back="true" :hide-menu="true" :location="{ name: 'Avonden' }" :force="true" />
    <div class="main-wrapper">
      <div class="container main-container">
        <p v-bind:class="{ 'mt-4': isNativeApp }">Wijzig je gegevens hieronder.</p>
        <message :error="error" :success="success" />
        <div class="image"></div>
        <div class="form">
          <div class="row top">
            <div class="col-6">
              <div class="field field-voornaam">
                <label for="voornaam">Voornaam</label>
                <input type="text" id="voornaam" v-bind:value="this.$root.user.Name" v-on:input="user.FirstName = $event.target.value"/>
              </div>
            </div>
            <div class="col-6">
              <div class="field field-achternaam">
                <label for="achternaam">Achternaam</label>
                <input type="text" id="achternaam" v-bind:value="this.$root.user.LastName" v-on:input="user.Surname = $event.target.value"/>
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label for="email">E-mailadres</label>
                <input type="text" id="email" v-bind:value="this.$root.user.Email" v-on:input="user.Email = $event.target.value" />
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label for="type">Rol</label>
                <div class="row">
                  <div v-bind:key="index" v-for="(rol, index) in this.$root.user.Function" class="d-flex align-items-center col-6">
                    <label :for="index" class="pt-1 pb-1 ps-2 user-label"><img :src="rol.Icon" /> {{ rol.Naam }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>Profielfoto</label>
            </div>
            <div class="col-12 profiel-foto">
              <div class="field">
                <input type="file" ref="file" @change="uploadFile" style="display: none">
                <span class="profile-icon" @click="$refs.file.click()">
                  <span v-if="$root.user.Image != null && !this.uploading" class="img" v-bind:style="{ backgroundImage: 'url(data:image/png;base64,' + $root.user.Image + ')' }" />
                  <div v-if="this.uploading" class="uploading" />
                </span>
              </div>
              <div class="right">
                <div>Voeg hier een leuke of gekke foto van jezelf toe! 📸  Dit is vooral in het begin handig als je stiekem de namen van je teamleden nog oefent! 😉 </div>
                <div class="button border-blue button-profile-picture" @click="$refs.file.click()">Wijzig profielfoto</div>
              </div>
            </div>
            <div class="col-12 change-password">
              <label>Wachtwoord</label>
              <router-link to="/password">Mijn wachtwoord opnieuw instellen</router-link>
            </div>
            <div class="col-12">
              <a @click="saveProfile" class="button grass-green button-save">Opslaan</a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="container">
          <div class="button border-red" @click="logout">Uitloggen uit de app</div>
          <div class="txt">Je weet het, de deur staat altijd open.</div>
          <div class="logo"></div>
          <div class="image"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react";
import ProfileIcon from "@/views/components/profile-icon";
import store from "@/store";
import GlobalHeader from "@/views/components/global-header";
import Message from "./components/message-element";

export default {
  components: {Message, GlobalHeader},
  data() {
    return {
      user: {},
      error: null,
      success: null,
      loaded: false,
      isNativeApp: react.isNativeApp(),
      uploading: false,
    }
  },
  created() {
    setTimeout(() => {
      this.$root.doneLoading()
      this.loaded = true;
    }, 500);

    react.postMessageToApp('cameraPermission');
  },
  methods: {
    saveProfile() {
      httpClient.post('/user', JSON.stringify(this.user)).then(response => {
        this.$root.user = response.data
        this.success = "Yeaaah gelukt! Je profiel is weer helemaal up to date!";
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
    },
    logout() {
      react.postMessageToApp('logout', this.$root.user.ID);
      store.commit('setPath', null)
      localStorage.removeItem('token');
      location.reload()
    },
    uploadFile(event) {
      this.uploading = true
      var files = event.target.files
      const formData = new FormData();
      for (const i of Object.keys(files)) {
        formData.append('files', files[i])
      }
      httpClient.post('/image', formData).then(response => {
        this.$root.user = response.data
        this.uploading = false
      }).catch(error => {
        console.log(error)
        this.$notify({
          type: "error",
          title: "Foto toevoegen mislukt",
          text: "Het toevoegen van je profielfoto is mislukt. Je foto is te groot of voldoet niet aan het juiste formaat.",
        });
        this.uploading = false
      })
    }
  }
}
</script>
