<template>
  <div class="dialog dialog-duur" ref="dialogDuur">
    <div class="container">
      <div class="title">Wijzig duur van onderdeel</div>
      <div class="buttons">
        <template v-for="tijd in tijden" v-bind:key="tijd">
          <div class="button border-lightblue-white"
               v-bind:class="{'active': tijd === this.selectedTime}"
              @click="selectedTime = tijd">{{tijd}} min</div>
        </template>
      </div>
      <div class="eigen-duur">
        of kies je eigen duur:
        <div class="duur">
          <input type="number" min="0" max="999" v-model="customTime" maxlength="3" placeholder="120" />
          <span class="txt">min</span>
        </div>
      </div>
      <div class="wijzig-starttijd">
        <div class="title text-white mb-5">Wijzig starttijd van onderdeel</div>
        <div class="d-flex justify-content-center align-items-center pb-5">
          <span class="starttijd text-white pe-4">Starttijd:</span>
          <input type="time" class="mb-0" v-model="startTime"/>
        </div>
      </div>

      <div class="button grass-green" @click="save()">Opslaan</div>
      <div class="logo"></div>
    </div>
    <div class="close" v-bind:class="{ 'mt-5': isNativeApp }" @click="$root.closeDialog($refs, 'dialogDuur')"></div>
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react";
import moment from "moment/moment";

export default {
  name: "dialog-duur",
  props: ['tijd', 'datum'],
  watch: {
    selectedTime(value) {
      if(value != null) {
        this.customTime = null;
      }
    },

    customTime(newValue, oldValue) {
      if (newValue != null) {
        if(newValue.length > 3)
          this.customTime = oldValue

        this.selectedTime = null;
      }
    }
  },
  data() {
    return {
      startTime: null,
      selectedTime: null,
      customTime: null,
      newDate: null,
      tijden: [5, 10, 15, 20, 25, 30, 45, 60, 75, 90],
      isNativeApp: react.isNativeApp(),
    }
  },
  created() {
    let date = moment.utc(this.datum).toDate()
    this.startTime = moment.utc(date).format('HH:mm');
    console.log(this.startTime)

    if(this.tijden.includes(this.tijd)) {
      this.selectedTime = this.tijd
    } else {
      this.customTime = this.tijd === 0 ? null : this.tijd
    }
  },
  methods: {
    save() {
      let time = parseInt(this.getTime())
      let date = this.stringToDate();
      httpClient.post('/item/duration/' + this.$route.params.itemId,
          JSON.stringify({Time: time, Date: date}))
          .then(response => {
            //this.$emit("ok", time)
            this.$emit("ok", {time: time, date: this.newDate, hour: this.startTime})
            this.$root.closeDialog(this.$refs, 'dialogDuur')
          });
    },
    stringToDate() {
      let date = moment.utc(this.datum).toDate()
      let month = ('0' + (date.getMonth()+1)).slice(-2);
      let day = ('0' + date.getDate()).slice(-2);
      let string = date.getFullYear() + '-' +month+ '-' + day + 'T' + this.startTime +'+00:00';
      this.newDate = string
      return Date.parse(string) / 1000;
    },
    getTime() {
      if(this.selectedTime != null) {
        return this.selectedTime
      } else if(this.customTime != null) {
        return this.customTime
      } else {
        return -1;
      }
    }
  }
}
</script>
