<template>
  <div class="dialog dialog-date" ref="dialogDate">
    <div class="container">
      <div class="title">Wijzig datum + tijd</div>
      <datepicker v-model="newDate" :locale="locale"/>
      <div>
        <span class="starttijd">Starttijd:</span>
        <input type="time" v-model="newTime"/>
      </div>
      <a class="button grass-green no-margin" @click="updateDate">Opslaan</a>
      <div class="logo"></div>
    </div>
    <div class="close" v-bind:class="{ 'mt-5': isNativeApp }"  @click="$root.closeDialog($refs, 'dialogDate')"></div>
  </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import { httpClient } from "@/api/httpClient";
import moment from "moment";
import react from "@/functions/react";
import {nl} from "date-fns/locale";

export default {
  components: {
    Datepicker
  },
  props: ['date', 'time', 'id'],
  data() {
    return {
      newDate: null,
      newTime: null,
      locale: nl,
      isNativeApp: react.isNativeApp(),
    }
  },
  created() {
    if(this.date == null) {
      this.newDate = new Date();
    } else {
      this.newDate = moment.utc(this.date).toDate()
    }
    this.newTime = moment.utc(this.newDate).format('HH:mm');
  },
  methods: {
    stringToDate() {
      var month = ('0' + (this.newDate.getMonth()+1)).slice(-2);
      var day = ('0' + this.newDate.getDate()).slice(-2);
      var string = this.newDate.getFullYear() + '-' +month+ '-' + day + 'T' + this.newTime +'+00:00';
      this.newDate = moment.utc(string).toDate()
      return Date.parse(string) / 1000;
    },
    updateDate() {
      var date = this.stringToDate();
      httpClient.post('/item/date/' + this.id, JSON.stringify(
          {timestamp: date}
      )).then(response => {
        this.$root.closeDialog(this.$refs, 'dialogDate')
        this.$emit("ok", {time: this.newTime, date: this.newDate})
      })

    }
  }
}
</script>
