<template>
  <div class="Login-Page">
    <div class="header-large">
      <div class="top container"></div>
      <div class="center">
        <div class="container main-container">
          <div class="content-wrapper">
            <router-link to="/aanmeldcode" class="aanmeldcode" v-bind:class="{ 'mt-5': isNativeApp }"></router-link>
            <div class="top-wrapper">
              <div class="logo-wit" v-bind:class="{ 'mt-6': isNativeApp }"></div>
              <div class="title">Alpha Youth</div>
              <div class="inleiding"></div>
              <message :error="error" :success="success" />
              <form class="form login-form" @submit.prevent>
                <input type="email" placeholder="E-mailadres" v-model="username" /><br />
                <input type="password" placeholder="Wachtwoord" v-model="password" /><br />
                <div class="buttons">
                  <button @click.stop="onSubmit" class="button grass-green button-save">Inloggen</button>
                  <router-link to="/forgot" class="forgot-password">Wachtwoord vergeten</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container no-padding">
      <div class="content-wrapper">
        <div class="vrienden"></div>
        <div class="txt">
          Heb je nog geen account? Vraag je teamleider je een uitnodigingslink te sturen.
        </div>
      </div>
    </div>
    <modal-loading v-if="loading" :title="`Inloggen...`"/>
  </div>
</template>
<script>
import router from "@/router";
import { httpClient } from '../api/httpClient';
import react from "@/functions/react";
import store from "@/store";
import Message from "@/views/components/message-element";
import ModalLoading from "@/views/components/modal-loading";
export default {
  components: {ModalLoading, Message},
  form: {
    name: String
  },
  created() {
    this.$root.doneLoading();
    if(this.$route.query.reset) {
      this.success = "Gelukt, je wachtwoord is aangepast. Je kunt er nu mee inloggen!";
    } else if(this.$route.query.error) {
      this.error = "Er ging iets mis, wil je opnieuw inloggen?";
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      httpClient.post('/user/login', {
            Email: this.$data.username,
            Password: this.$data.password
        }).then((response) => {
            this.error = null
            this.$root.user = response.data
            react.postMessageToApp('login', this.$root.user.ID);
            if(store.state.path !== null) {
              try {
                router.push(store.state.path)
              } catch (e) {
                router.push('/')
              }
            } else {
              router.push('/')
            }
            this.loading = false;
          }, (error) => {
            this.loading = false;
            this.error = "Oeps, je komt er niet in. Je e-mailadres of je wachtwoord klopt niet! Probeer het opnieuw."
            this.success = null;
          });
    }
  },
  data() {
    return {
      username: "",
      password: "",
      error: null,
      success: null,
      loading: false,
      label: "Test 1234",
      isNativeApp: react.isNativeApp(),
    };
  }
}
</script>
