<template>
  <div class="Team-Page header-with-tabs" v-if="gebruikers">
    <global-header title="Je team" :back="false" :location="{ name: 'Home' }" />
    <div class="container main-container">
      <p>Dit is je team, samen gaan jullie een prachtige Alpha Youth neerzetten!</p>
      <message :error="error" :success="success" />
      <div v-for="gebruiker in gebruikers" v-bind:key="gebruiker" class="gebruiker">
        <avatar :user="gebruiker" />
        <div class="row">
          <div class="col-5 col-name d-flex flex-column align-items-start">

            <div class="name">
              {{ gebruiker.Name }}
              {{ gebruiker.LastName }}
            </div>
            <div class="email">
              {{ gebruiker.Email }}
            </div>
          </div>
          <div class="col-sm-3 col-icons">
            <span v-bind:key="index" v-for="(val, index) in gebruiker.Function">
              <img :src="val.Icon" />
            </span>
          </div>
          <div class="col-sm-4 col-roles">
            <span v-bind:key="index" v-for="(val, index) in gebruiker.Function">
              {{ val.Naam }}
            </span>
          </div>
        </div>
        <div class="controls" v-if="$root.isAdmin()">
          <span @click="$router.push({name: 'User', params: {id: gebruiker.ID}})" class="edit"></span>
        </div>
      </div>
    </div>
    <div class="container no-padding">
      <div class="footer " v-if="$root.isAdmin()">
        <div class="expand-team">
          <div class="txt-wrapper">
            <div class="titel">Je team uitbreiden</div>
            <div class="txt">Deel deze unieke link waarmee teamleden zich automatisch kunnen toevoegen aan je team.</div>
          </div>
          <div class="link-wrapper" >
            <input type="text" v-bind:value="getPageUrl()" ref="input" v-tooltip:bottom="{title: `Gekopieerd`, trigger: 'manual', visible: this.tooltipVisible}" readonly/>
            <div class="button-kopieer" @click="copyLink()">Kopieer</div>
          </div>
        </div>
        <div class="footer-txt">Een beheerder heeft dezelfde rechten als jij, je beslist over de invulling en verantwoordelijkheden van de avonden. Een teamlid kan alleen voorkeuren aangeven.</div>
        <div class="footer-image"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import Avatar from "@/views/components/avatar-element";
import Message from "@/views/components/message-element";
import ProfileIcon from "@/views/components/profile-icon";
import GlobalHeader from "@/views/components/global-header";
export default {
  components: {GlobalHeader, Avatar, Message},
  created() {
      httpClient.get('/cursus')
        .then(response => {
          this.cursus = response.data
        })
        .then(() => {
          httpClient.get('/gebruikers')
          .then(response => {
              this.gebruikers = response.data
          })
          .finally(() => {
            this.$root.doneLoading();
          });
        })
    },
    data() {
        return {
            gebruikers: null,
            cursus: null,
            gebruiker: null,
            showModal: false,
            tooltipVisible: false,
            error: null,
            success: null
        }
    },
  methods: {
    getPageUrl() {
      var url = window.location.href;
      var arr = url.split("/");
      return  arr[0] + "//" + arr[2] + this.cursus.Link;
    },
    copyLink() {
      this.$refs.input.select();
      document.execCommand('copy');
      this.tooltipVisible = true;
      setTimeout(() => { this.tooltipVisible = false }, 1000);
    }
  }
}
</script>
