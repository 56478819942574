<template>
  <div class="Clear-page"></div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import Message from "@/views/components/message-element";
import router from "@/router";

export default {
  created() {
    localStorage.clear()
    router.push('/login')
  }
}
</script>
