<template>
  <div class="Login-Page">
    <div class="header-large">
      <div class="top"></div>
      <div class="center">
        <div class="container main-container">
          <div class="content-wrapper">
            <div class="top-wrapper">
              <div class="logo-wit" v-bind:class="{ 'mt-6': isNativeApp }"></div>
              <div class="title">Alpha Youth</div>
              <div class="subtitle">Wachtwoord vergeten</div>
              <div class="inleiding"></div>
              <message :success="success" :error="error" />
              <form class="form login-form" @submit.prevent>
                <input type="text" placeholder="E-mailadres" v-model="username" /><br />
                <div class="buttons">
                  <button @click="onSubmit" class="button grass-green button-save">Herstel wachtwoord</button>
                  <router-link to="/login" class="forgot-password">Terug naar inloggen</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container no-padding">
      <div class="content-wrapper">
        <div class="vrienden"></div>
      </div>
    </div>
    <modal-loading v-if="loading" :title="`Gegevens checken...`"/>
  </div>
</template>
<script>
import { httpClient } from '../api/httpClient';
import react from "@/functions/react";
import Message from "@/views/components/message-element";
import ModalLoading from "@/views/components/modal-loading";
export default {
  components: {ModalLoading, Message},
  created() {
    this.$root.doneLoading();
  },
  methods: {
    onSubmit() {
      this.loading = true
      localStorage.clear()
      httpClient.post('/user/forgotPassword', {
        Email: this.username
      }).then((response) => {
        this.error = null
        this.success = 'Als je e-mailadres een belletje doet rinkelen in ons systeem, sturen we je nu een e-mail met instructies! Check vlug je inbox!'
        this.username = null
        this.loading = false
      }, (error) => {
        this.error = "Onjuiste logingegevens"
        this.success = null;
        this.loading = false
      });
    }
  },
  data() {
    return {
      username: "",
      success: null,
      error: null,
      loading: false,
      isNativeApp: react.isNativeApp(),
    };
  }
}
</script>
