<template>
  <div class="User-Page header-without-tabs" v-if="fetchedUser">
    <div class="header">
      <div class="inner-header">
        <div class="container">
          <div class="top">
            <span class="back-button" v-bind:class="{ 'mt-5': isNativeApp }" @click="$root.backClick({ name: 'Home' })"></span>
            <profile-icon />
          </div>
          <div class="bottom">
            <div class="row">
              <div class="col-lg-8">
                <div class="title">Gebruiker wijzigen</div>
              </div>
              <div class="col-lg-4">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container main-container">
      <p>Wijzig de gegevens hieronder.</p>
      <message :error="error" :success="success" />
      <div class="row form">
        <div class="col-6">
          <div class="field">
            <label for="voornaam">Voornaam</label>
            <input type="text" id="voornaam" v-bind:value="this.fetchedUser.Name" v-on:input="user.FirstName = $event.target.value"/>
          </div>
        </div>
        <div class="col-6">
          <div class="field">
            <label for="achternaam">Achternaam</label>
            <input type="text" id="achternaam" v-bind:value="this.fetchedUser.LastName" v-on:input="user.Surname = $event.target.value"/>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="email">E-mailadres</label>
            <input type="text" id="email" v-bind:value="this.fetchedUser.Email" v-on:input="user.Email = $event.target.value" />
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="type">Rol</label>
            <div class="row">
            <div v-bind:key="index" v-for="(rol, index) in this.fetchedUser.Functions" class="d-flex align-items-center col-6">
              <input :id="index" :value="rol.ID" name="product" type="checkbox" v-model="this.user.Function" />
              <label :for="index" class="pt-1 pb-1 ps-2 user-label"><img :src="rol.Icon" /> {{ rol.Naam }}</label>
            </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="field">
            <label for="type">Type gebruiker</label>
            <select name="type" id="type" v-bind:value="this.fetchedUser.Role" v-on:change="user.Role = $event.target.value">
              <option value="0">Beheerder</option>
              <option value="1">Teamlid</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <label>&nbsp;</label>
          <a @click="saveProfile" class="button grass-green button-save">Opslaan</a>
          <a @click="confirmDeleteUser" class="button red button-save">Verwijder</a>
        </div>
      </div>
    </div>
    <modal-dialog v-if="showModal" @ok="deleteUser()" @cancel="showModal = false" :title="'Weet je zeker dat je ' + this.fetchedUser.Name  + ' wilt verwijderen?'" />
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react"
import moment from "moment";
import ModalDialog from "@/views/components/modal-dialog";
import Message from "./components/message-element";
import ProfileIcon from "@/views/components/profile-icon";
import router from "../router";

export default {
  components: {ProfileIcon, Message, ModalDialog},
  data() {
    return {
      user: {},
      error: null,
      success: null,
      fetchedUser: null,
      showModal: false,
      isNativeApp: react.isNativeApp(),
    }
  },
  created() {
    httpClient.get('/user/' + this.$route.params.id)
        .then(response => {
          this.fetchedUser = response.data;
          this.user.Function = response.data.Function.map(f => f.ID);
        },
        response => {
          console.log(response.status)
        })
        .finally(() => {
          this.$root.doneLoading();
        });
  },
  methods: {
    saveProfile() {
      httpClient.post('/user/' + this.$route.params.id, JSON.stringify(this.user)).then(response => {
        this.success = "Yeaaah gelukt! Het profiel is weer helemaal up to date!";
      })
    },
    confirmDeleteUser() {
      this.showModal = true
    },
    deleteUser() {
      httpClient.delete('/user/' + this.fetchedUser.ID)
          .then(response => {
            this.success = "Gebruiker is succesvol verwijderd"
            router.push({ path: '/team', replace: true })
          }).catch(e => {
        this.success = null;
        this.error = "Er ging iets fout bij het verwijderen van de gebruiker."
      })

    }
  }
}
</script>
