import axios from 'axios';

export const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 60000
});

const getUserProfile = () => localStorage.getItem('profile');
const getAuthToken = () => localStorage.getItem('token');

const authInterceptor = (config) => {
    if(getAuthToken() != null) {
        config.headers['Authorization'] = getAuthToken();
    }
    return config;
}




const errorHandler = (error) => {
    if (error.response.status === 403) {
        localStorage.removeItem('token');
        window.location = '/';
    }

    return Promise.reject({ ...error })
}

const responseInterceptor = (response) => {

    if (response.status === 401) {
        localStorage.removeItem('token');
        window.location = '/login';
    }

    if(response.status === 500) {
        localStorage.removeItem('token');
        window.location = '/login?error=unknown';
    }

    let token = response.headers['access-token'];
    if (token != undefined) {
        localStorage.setItem('token', token);
    }
    return response
}


httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(
    response => responseInterceptor(response),
    error => errorHandler(error)
)

export const fetcher = url => httpClient.get(url).then(res => res.data).catch(error => {
    if(url === "/course/evenings") {
        localStorage.removeItem('token');
        window.location.reload()
    }
});
