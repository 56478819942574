export var accordion = function() {
    setTimeout( () => {
        var list = document.getElementsByClassName("panel")
        for (let item of list) {
            var header = item.querySelector(".panel-header");
            header.addEventListener("click", function() {
                item.classList.toggle("visible")
            });
            console.log(header);
        }
    })
}