import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";
import Login from "@/views/login-page.vue";
import {authGuard} from "@/auth/authGuard";
import Avonden from "@/views/avonden-page";
import Item from "@/views/item-page";
import Team from "@/views/team-page";
import Avond from "@/views/avond-page";
import User from "@/views/user-page";
import Registreer from "@/views/registreer-page";
import Aanmeldcode from "@/views/aanmeldcode-page";
import TekstPagina from "@/views/tekst-page";
import Profiel from "@/views/profiel-page";
import Password from "@/views/password-page";
import Reset from "@/views/reset-page";
import Forgot from "@/views/forgot-page"
import Promo from "@/views/promo-page"
import Clear from "@/views/clear-page"

const routes = [
    {
        path: "/",
        alias: '/avonden',
        name: "Avonden",
        component: Avonden,
        beforeEnter: authGuard
    },
    {
        path: "/avond/:id",
        name: "avond",
        component: Avond,
        props: true,
        beforeEnter: authGuard
    },
    {
        path: "/item/:itemId",
        name: "item",
        component: Item,
        props: true,
        beforeEnter: authGuard
    },
    {
        path: "/team",
        name: "Team",
        component: Team,
        beforeEnter: authGuard
    },
    {
        path: "/team/:id",
        name: "User",
        component: User,
        beforeEnter: authGuard
    },
    {
        path: "/page/:id",
        name: "Page",
        component: TekstPagina,
        beforeEnter: authGuard
    },
    {
        path: "/profiel",
        name: "Profiel",
        component: Profiel,
        beforeEnter: authGuard
    },
    {
        path: "/password",
        name: "Password",
        component: Password,
        beforeEnter: authGuard
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/clear",
        name: "Clear",
        component: Clear,
    },
    {
        path: "/aanmeldcode",
        name: "Aanmeldcode",
        component: Aanmeldcode,
    },
    {
        path: "/registreer/:token",
        name: "registreer",
        component: Registreer,
    },
    {
        path: "/forgot",
        name: "forgot",
        component: Forgot,
    },
    {
        path: "/reset/:id/:token",
        name: "reset",
        component: Reset,
    },
    {
        path: "/promotie-materiaal",
        name: "promo",
        component: Promo,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next)=>{
    if( to.name === "Avonden" ||
        to.name === "avond" ||
        to.name === "item" ||
        to.name === "team") {
        store.commit('setPath', to)
    }
    store.commit('setLoading', true)
    next();
})
router.afterEach((to, from)=>{
    // setTimeout(function() {
    //     store.commit('setLoading', false)
    // }, 200)

})

export default router;
