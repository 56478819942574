<template>
  <div class="item-container" @click="itemClick(element)" v-bind:class="element.Theme">
    <div class="tijd">
      {{ element.Hour }}
<!--      <span v-if="element.Date">{{ element.Date}}</span>-->
<!--      <span v-else>{{ element.Hour }}</span>-->
    </div>
    <div class="info">
      <div class="row">
        <div class="col-md-6 title-wrapper">
          <div class="title">
            <div class="main">{{ element.Title }}</div>
            <div class="sub">
              <span v-if="element.Subtitle != null">{{ element.Subtitle }}</span>
              <span v-else-if="element.OptionCount > 0 && $root.isAdmin()" class="pick-option">Kies een optie</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 center-vertical">
          <div class="person">
            <avatar :user="element.Owner" />
            <div class="owners" v-if="element.Owners.length != 0">
              <template v-for="owner in element.Owners" v-bind:key="owner">
                <avatar :user="owner" />
              </template>
            </div>
            <span class="name pick-owner" v-else-if="$root.isAdmin()">Kies owner</span>
            <span class="name pick-owner" v-else>Nog geen owner</span>
          </div>

        </div>
      </div>

      <div class="image" v-bind:class="{ 'image-bottom': element.Alignment == 1 }" v-bind:style="{ 'background-image': 'url(' + element.Illustratie + ')' }"></div>
      <div class="handle" v-if="$root.isAdmin() && element.Date" >
        <div class="icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import Avatar from "@/views/components/avatar-element";

export default {
  components: {
    Avatar
  },
  props: ['element'],
  methods: {
    itemClick(item) {
      router.push({name: 'item', params: {itemId: item.ID}})
    }
  }

}
</script>