<template>
  <div class="message">
    <div class="success" v-if="success">
      <span v-html="success"></span>
    </div>
    <div class="error" v-if="error">
      <span v-html="error"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['success', 'error']
}
</script>