export const authGuard = (to, from, next) => {

    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    const isAuthenticated = () => {
        let token = localStorage.getItem('token');
        if (token != undefined) {
            let parsedToken = parseJwt(token)
            let curTimestamp = new Date().getTime() / 1000
            if (parsedToken.exp > curTimestamp) {
                return true;
            }
        }
        return false;
    }

    const fn = () => {
        if(isAuthenticated()) {
            next();
        }

        next('/login')

    };

    fn();
};