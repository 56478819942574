<template>
  <div class="Item-Page" v-if="item" v-bind:class="item.Theme">
    <div class="header">
      <div class="container print-only print-top">Bijeenkomst # {{ item.Index }}  •  {{ item.AvondTitle }}  •  {{ getNiceDate() }}</div>
      <div class="inner-header">
        <div class="container">
          <div class="top">
            <span v-if="!item.isAvond" class="back-button" v-bind:class="{ 'mt-6': isNativeApp }" @click="$root.backClick({name: 'Avonden'}, true)"></span>
            <span v-else class="back-button" v-bind:class="{ 'mt-6': isNativeApp }" @click="$root.backClick({name: 'avond', params: {id: item.AvondID}}, true)"></span>
            <profile-icon />
          </div>
          <div class="bottom">
            <div class="left">
              <span class="inner-time" ref="headerTime">{{ item.Hour }}</span>
            </div>
            <div class="right">
                <div class="title">{{ item.Title }}</div>
                <div class="subtitle" v-if="item.isAvond" >
                  <div v-if="item.Owners.length != 0" class="owners position-relative d-flex">
                   <template v-for="owner in item.Owners" v-bind:key="owner">
                    <avatar :user="owner" v-tooltip:bottom="{title: owner.Name + ' ' + owner.LastName, trigger: 'mouseover', dismiss: true}" />
                   </template>
                  </div>
                  <span v-else-if="$root.isAdmin()">Kies owner</span>
                  <span v-else>Nog geen owner</span>
                </div>
                <div class="print-only">
                  Duur: {{item.Time }} min
                </div>
                <div id="users" v-if="item.Interested.length != 0 && item.Owners.length == 0">
                  <template v-for="gebruiker in item.Interested" v-bind:key="gebruiker" >
                    <avatar :user="gebruiker" v-tooltip:bottom="{title: gebruiker.Name + ' ' + gebruiker.LastName, trigger: 'mouseover'}" />
                  </template>
                </div>
            </div>
          </div>
          <div class="image" v-bind:class="{ 'image-bottom': item.Alignment == 1, 'mt-2': isNativeApp }" v-bind:style="{ 'background-image': 'url(' + item.Illustratie + ')' }"></div>
          <div class="line" :style="{ height: lineHeight }"></div>
          <div class="change-date" v-if="!item.isAvond" @click="$root.openDialog($refs.dialogDate.$refs, 'dialogDate')"></div>
          <div class="print" onclick="window.print()"></div>
        </div>
      </div>
    </div>
    <div class="container content">
      <div class="buttons" v-if="item.isAvond">

        <div class="button border-lightblue has-icon" @click="ownClick" v-if="item.isAvond && item.Owner == null" v-bind:class="{ active: !containsUser($root.user) }" >
          <avatar :user="$root.user"  class="icon" />
          Wil ik wel doen
        </div>

        <!-- Admin section for choosing owner -->
        <template v-if="$root.isAdmin() && item.isAvond">
          <div class="button border-blue" @click="$root.openDialog($refs, 'dialogOwner')">
            <span v-if="item.Owner != null">Wijzig owner</span>
            <span v-else>Kies owner</span>
          </div>
          <div class="button border-blue has-icon button-duur" @click="$root.openDialog($refs.dialogDuur.$refs, 'dialogDuur')">
            <span class="icon"></span>
            Duur: {{item.Time }} min
          </div>
        </template>
        <span v-else>
          <div class="button border-blue has-icon button-duur txt-duur">
            <span class="icon"></span>
            Duur: {{item.Time }} min
          </div>
        </span>
      </div>
      <span v-html="item.Content" class="global-text"></span>
    </div>
    <div class="food" v-if="item.Food">
      <div class="container" v-bind:style="{ 'background-image': 'url(' + item.Illustratie + ')' }">
        <div class="title">Wat gaan we eten?</div>
        <input type="text" v-if="$root.isAdmin()" v-model="item.Subtitle" placeholder="Vul maar wat in..." />
        <div class="dish" v-else></div>
      </div>
    </div>
    <div class="options" v-if="item.Options && !item.Food">
      <div class="container">
        <ul id="options">
          <li v-for="(item, index) in item.Options" v-bind:key="item.ID">
            <div class="vote-count" @click="optionClick(item.ID)" v-bind:class="{ active: item.Voted }">
              <span class="heart"></span>
              <span>
                {{ item.Count }}
              </span>
            </div>
            <div class="vote-button button border-blue" @click="setActiveItem(item)" v-bind:class="{ active: activeItem === item, selected: item.Selected }">
              Optie {{ index + 1}}
            </div>
          </li>
        </ul>
        <div class="options-content global-text" v-if="activeItem != null">
          <div class="titel">{{ activeItem.ContentTitel }}</div>
          <div class="subtitel">
            <span class="duur">
              Duur: {{ activeItem.Duur }} min
            </span>
            <span class="voorbereiding">
              Voorbereiding: {{ activeItem.Voorbereiding}} min
            </span>
          </div>

          <span v-html="activeItem.Content"></span>
          <div class="button green button-pick" v-show="$root.isAdmin()" v-bind:class="{ active: activeItem.Selected }" @click="pickOption(activeItem.ID)">Kies deze optie</div>
        </div>
      </div>
    </div>
    <div class="next-item" v-if="item.Next">
      <div class="container">
        <div class="next-title" >Onderdeel wat hierna komt:</div>
        <item-element :element="item.Next" ref="nextItem" />
      </div>
    </div>
    <div ref="footerTime" />

    <div class="dialog dialog-owner" ref="dialogOwner">
      <div class="container">
        <div class="title">Kies owner</div>
        <div class="owners">
          <div v-for="gebruiker in item.Interested" v-bind:key="gebruiker.ID"
               @click.stop="() =>{ addOwner(gebruiker) }" class="owner"
               v-bind:class="{ 'selected' : isOwner(gebruiker) }">
            <avatar :user="gebruiker" />
            <div class="name">
              {{ gebruiker.Name }} <br />
              {{ gebruiker.LastName }}
            </div>
          </div>
        </div>
        <div class="niet-gemeld">Niet gemeld voor dit onderdeel:</div>
        <div class="owners">
        <template v-for="gebruiker in gebruikers" v-bind:key="gebruiker.ID">
          <div class="owner" v-if="containsUser(gebruiker)"
               @click.stop="() => { addOwner(gebruiker) }"
               v-bind:class="{ 'selected' : isOwner(gebruiker) }">
            <avatar :user="gebruiker" />
            <div class="name">
              {{ gebruiker.Name }} <br />
              {{ gebruiker.LastName }}
            </div>
          </div>
        </template>
        </div>
        <div class="niet-gemeld extern">Of vul een externe naam in:</div>
        <div class="form">
          <input type="text" v-model="externalOwner" placeholder="Naam" />
        </div>
        <div class="button grass-green" @click="changeOwnerClick()">Opslaan</div>
        <div class="logo"></div>
      </div>
      <div class="close" v-bind:class="{ 'mt-5': isNativeApp }" @click="$root.closeDialog($refs, 'dialogOwner')"></div>
    </div>
    <dialog-duur ref="dialogDuur" :tijd="item.Time" :datum="item.Datum" v-on:ok="changeTime" />
    <dialog-datum ref="dialogDate" :id="item.ID" :date="item.Datum" v-on:ok="changedDate" />
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import react from "@/functions/react"
import ItemElement from "@/views/components/item-element";
import Avatar from "@/views/components/avatar-element";
import ProfileIcon from "@/views/components/profile-icon";
import DialogDuur from "@/views/components/dialog-duur";
import { accordion } from "../functions/accordion";
import moment from "moment";
import DialogDatum from "./components/dialog-datum.vue";

export default {
  components: {
    DialogDatum,
    // DialogDatum,
    DialogDuur,
    ProfileIcon,
    Avatar,
    ItemElement
  },
  computed: {
    dish() {
      return this.item !== null && this.item.Subtitle !== undefined ? this.item.Subtitle : null;
    }
  },
  data() {
    return {
      item: null,
      activeItem: null,
      gebruikers: {},
      lineHeight: "50px",
      selectedOwner: null,
      selectedOwners: [],
      externalOwner: null,
      timeout: null,
      isNativeApp: react.isNativeApp(),
    }
  },
  watch: {
    dish(value) {
      if(this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.setDish()
      }, 500)
    },
    externalOwner(value) {
      if(value != null) {
        this.item.Owners =  this.item.Owners.filter(item => item.External === true );
      }
    }
  },
  methods: {
    getNiceDate() {
      if(this.item.Datum) {
        const d = new Date(this.item.Datum);
        return moment(d).format('LLLL')
      }
    },
    changedDate(value) {
      this.item.Hour = moment.utc(value.date).format('HH:mm');
    },
    setDish() {
      httpClient.post('/item/dish/' + this.$route.params.itemId,JSON.stringify({Dish: this.dish}))
    },
    changeTime(value) {
      this.item.Time = value.time;
      this.item.Datum = value.date;
      this.item.Hour = value.hour;
    },
    containsUser(user) {
      try {
        return this.item.Interested.findIndex(o => o.ID === user.ID) === -1
      } catch(e) {
        return true;
      }
    },
    optionClick(id) {
      httpClient.post('/vote', JSON.stringify({OptionID: id}))
          .then(response => {
            this.item.Options.forEach(function (value, index) {
              if (value.ID === id) {
                value.Count = response.data.Count
                value.Voted = response.data.Voted
              }
            });
          })
    },
    pickOption(id) {
      httpClient.post('/item/option/' + id)
          .then(response => {
                this.item.Options.forEach(function (value, index) {
                  if (value.ID === id && response.data.selected)
                    value.Selected = 1
                  else
                    value.Selected = 0;
                });
          });

    },
    setActiveItem(item) {
      this.activeItem = item
      this.calculateHeight()
    },
    calculateHeight() {
      this.$nextTick(() => {
        var bottomElement = document.getElementsByClassName("tijd")[0];
        if(bottomElement !== undefined) {
          var bottom = bottomElement.getBoundingClientRect().bottom;
          var top = this.$refs.headerTime.getBoundingClientRect().bottom;
          var diff = (bottom - top) + 50//- 20;
          this.lineHeight = diff + "px";
        }
      });
    },
    changeOwnerClick() {
      let owners = this.item.Owners.map(o => o.ID)
      //if(owners.length > 0) {
        var data = { ItemID: this.$route.params.itemId, Owners: owners, External: this.externalOwner };
        httpClient.post('/owner', JSON.stringify(data))
            .then(response => {
              if (response.data.Result) {
                this.item.Owners = response.data.Owners
              } else {
                this.item.Owners = null;
              }
              this.$root.closeDialog(this.$refs, 'dialogOwner')
            })
      //}
    },
    addOwner(owner) {
      this.externalOwner = null
      var index = this.item.Owners.findIndex(o => o.ID === owner.ID)
      if(index != -1) {
        this.item.Owners.splice(index, 1)
      } else {
        this.item.Owners.push(owner)
      }
    },
    isOwner(owner) {
      var index = this.item.Owners.findIndex(o => o.ID === owner.ID)
      if(index !== -1)
        return true
      else
        return false
    },
    ownClick() {
      httpClient.post('/own', JSON.stringify({ItemID: this.$route.params.itemId}))
          .then(response => {
            if (this.item.Interested == undefined)
              this.item.Interested = [];

            if (response.data.Result) {
              this.item.Interested.push(this.$root.user)
            } else {
              var index = this.item.Interested.findIndex(o => o.ID === this.$root.user.ID)
              this.item.Interested.splice(index, 1)
            }
          })
    },
  },
  created() {
    // get item
    httpClient.get('/item/' + this.$route.params.itemId)
        .then(response => {
          console.log(response)
          this.item = response.data
          // set default owner
          //this.selectedOwner = this.item.Owner;
          this.externalOwner = this.item.ExternalOwner;


          if (this.item.Options != undefined) {
            //Selected
            this.activeItem = this.item.Options[0];
            this.item.Options.forEach((value, index) => {
              if(value.Selected) {
                this.activeItem = value;
              }
            });
          }
          this.calculateHeight()
          accordion();
        })
        .finally(() => {
          this.$root.doneLoading();
        });

    //  get users
    httpClient.get('/gebruikers')
        .then(response => {
          this.gebruikers = response.data
          console.log(response.data)
        })
  },
}
</script>
