<template>
   <span class="avatar" v-if="user != null">
     <span v-if="user.Image" class="img" v-bind:style="{ backgroundImage: 'url(data:image/png;base64,' + user.Image + ')' }"></span>
     <span v-else>{{ user.Initials }}</span>
     <span :class="{ 'role-admin': user.Role == 1 }"></span>
  </span>
</template>
<script>
export default {

  props: ['user']
}
</script>