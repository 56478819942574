<template>
  <div class="date-column" v-if="avond">
    <span class="icon-cal" v-text="getDay()"></span>
    <span class="date" v-html="format_date()"></span>
    <span class="edit" v-if="$root.isAdmin() && editable"></span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    avond: {},
    editable: Boolean
  },
  methods: {
    getDay() {
      if (this.avond.Datum != null) {
        var date = this.stringToDate();
        return date.format('D')
      }
    },
    stringToDate() {
      var date = moment.utc(this.avond.Datum)
      // var time = moment.utc(this.avond.Time, 'HH:mm');
      //
      // date.set({
      //   hour: time.get('hour'),
      //   minute: time.get('minute'),
      //   second: time.get('second')
      // });
      return date;
    },
    format_date() {
      if (this.avond.Datum != null) {
        var date = this.stringToDate();
        return date.format('dddd D MMM YYYY <br />HH.mm uur')
      }
    }
  }
}
</script>

<style scoped>

</style>