<template>
  <transition name="fade" mode="out-in">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-title">
            <slot name="header">
              {{ title }}
            </slot>
          </div>
          <div class="modal-buttons">
              <div class="button red" @click="$emit('ok')">Verwijderen</div>
              <div />
              <div class="cancel" @click="$emit('cancel')">Annuleren</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['title']
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>