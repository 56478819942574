<template>
  <div class="Tekst-Page header-with-tabs" v-if="content">
    <global-header :title="content.Title" :back="true" :location="{ name: 'Home' }" />
    <div class="container main-container">
      <router-link to="/promotie-materiaal" class="button-promo">
        <div class="inner">Download ons gratis promotiemateriaal</div>
        <img src="@/assets/images/promotiemateriaal-mockup.png" />
      </router-link>
      <div class="txt global-text" v-html="content.Content"></div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/api/httpClient";
import { accordion } from "../functions/accordion";
import GlobalHeader from "@/views/components/global-header";

export default {
  components: {GlobalHeader},
  data() {
    return {
      content: null,
    }
  },
  created() {
    httpClient.get('/content/' + this.$route.params.id)
        .then(response => {
          this.content = response.data
          accordion();
        })
        .finally(() => {
          this.$root.doneLoading();
        });
  },
  mounted() {
  }
}
</script>
