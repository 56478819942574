<template>
  <span class="profile-icon-wrapper">
    <div 
      class="profile" 
      v-bind:class="{ 'mt-7': isNativeApp }" 
      @click="$router.push({ name: 'Profiel' })"
    >
      <span class="profile-name">
        {{ $root.user.Name }}
      </span>
       <avatar :user="$root.user"  class="profile-icon" />
    </div>
  </span>
</template>

<script>
import Avatar from "@/views/components/avatar-element";
import react from "@/functions/react"
export default {
  name: "profile-icon",
  components: {Avatar},
  data () {
    return {
        isNativeApp: react.isNativeApp()
    }
  },
}
</script>
